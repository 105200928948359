import { useEffect, useState } from 'react';

const useSSE = (url) => {
  const [dataSSE, setData] = useState(null);
  const [errorSSE, setError] = useState(null);
  const [loadingSSE, setLoading] = useState(true);

  useEffect(() => {
    const eventSource = new EventSource(url);

    // Handle incoming messages
    eventSource.onmessage = (event) => {
      try {
        const parsedData = JSON.parse(event.data); // Parse incoming JSON data
        setData(parsedData);
      } catch (err) {
        setError('Error parsing data');
      }
    };

    // Handle errors
    eventSource.onerror = (err) => {
      setError('Error with SSE connection');
      eventSource.close();
    };

    // Cleanup function when the component unmounts or URL changes
    return () => {
      eventSource.close();
    };
  }, [url]);

  return { dataSSE, errorSSE, loadingSSE };
};

export default useSSE;
