import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiConfig, { baseurlSocket } from "src/ApiConfig/ApiConfig";
import { calculateTimeLeft, getContract, swichNetworkHandler } from "src/utils";
import { apiRouterCall, getAPIHandler } from "src/ApiConfig/service";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import {
  ACTIVE_NETWORK,
  contractDecimals,
  getBalanceContract,
} from "src/constants";
import GetBalanceContractABI from "src/ABI/GetBalanceContractABI.json";
import useSSE from "src/hooks/useSSE";
import { getSpinStatus } from "src/service/spinApi";

export const AuthContext = createContext();
const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};
function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}
export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({ isGuideView: true });
  const [openDepositFlow, setOpenDepositFlow] = useState(false);
  const [endTime, setEndtime] = useState();
  const [isWalletUpdated, setisWalletUpdated] = useState(false);
  const [timeLeft, setTimeLeft] = useState();
  const [viewWalletData, setViewWalletData] = useState();
  const [tokenBalance, settokenBalance] = useState("");
  const [openReferralModal, setOpenReferralModal] = useState(false);
  const [isPotAmount, setisPotAmount] = useState({});
  const [lotteryBuyDisAble, setLotteryBuyDisAble] = useState(false);
  const [blcLoad, setBlcLoad] = useState(true);
  const [openDepositModal, setOpenDepositModal] = useState(false);
  const [toatlCounts, setTotalCounts] = useState(0);
  const [lastSpinDate, setLastSpinDate] = useState(false);
  const [totalCount, setTotalCount] = useState(false);
  const [bonus200Check, setbonus200Check] = useState(false);
  const [openSupport, setOpenSupport] = useState(false);
  const [eyeView, setEyeView] = useState({
    blackJack: 0,
    count: 0,
    derby: 0,
    derbyGame: 0,
    h2h: 0,
    home: 0,
    lottery: 0,
  });

  const HandleExtraSpin = async () => {
    try {
      getSpinStatus().then((res) => {
        const resonse = res?.data?.result;

        if (!resonse) return;
        // for free spin
        setTimeout(() => {
          setLastSpinDate(
            resonse?.lastSpinDate ? resonse?.lastSpinDate : false
          );
          setTotalCount(resonse?.freeSpin ? resonse?.freeSpin : false);
        }, 3000);
      });
    } catch (error) {
      setLastSpinDate(false);
    }
  };

  const { dataSSE, errorSSE, loadingSSE } = useSSE(ApiConfig.getLiveUser); // Replace with your SSE endpoint

  useEffect(() => {
    if (dataSSE) {
      setTotalCounts(dataSSE.count);
      setEyeView({
        blackJack: dataSSE.blackJack,
        count: dataSSE.count,
        h2h: dataSSE.h2h,
        derby: dataSSE.derby,
        derbyGame: dataSSE.derbyGame,
        home: dataSSE.home,
        lottery: dataSSE.lottery,
      });
    }
  }, [dataSSE]);

  const walletConnect = async () => {
    try {
      activate(injected, undefined, true)
        .then(() => {
          window.sessionStorage.setItem("ConnectWallet", true);
        })
        .catch((error) => {
          if (error) {
            toast.error(error.message);
            console.log("ERROR", error);
            const errorMSG = error.message; //+ ' Please install Metamask';
            activate(injected);
          }
        });
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getTokenWalletBalance = async () => {
    const contractObjToken = getContract(
      getBalanceContract,
      GetBalanceContractABI,
      library,
      account
    );
    const getTokenBalance = await contractObjToken.balanceOf(account);
    const toStngBl = getTokenBalance.toString();
    // const balancetoken = web3.utils.fromWei(toStngBl);
    settokenBalance(
      parseFloat(Number(toStngBl) / Math.pow(10, contractDecimals)).toFixed(3)
    );
  };

  const viewUserProfile = async (token) => {
    try {
      const response = await axios({
        url: ApiConfig.viewProfile,
        method: "GET",
        headers: {
          token: token,
        },
      });

      if (response.data.responseCode === 200) {
        setUserData(response.data.result);
        localStorage.setItem("userID", response.data.result._id);
        localStorage.setItem("profilePic", response.data.result.profilePic);
        localStorage.setItem("fullName", response.data.result.fullName);
        localStorage.setItem("userName", response.data.result.userName);
        getUserWallletApi();
        setbonus200Check(response.data.result.bonus200Check);
      } else {
        setUserData({});
      }
    } catch (error) {
      window.sessionStorage.removeItem("token");
      setIsLogin(false);
      setSession(null);
      localStorage.removeItem("ADMIN");
      localStorage.removeItem("referralCode");
      localStorage.removeItem("userID");
      localStorage.removeItem("profilePic");
      localStorage.removeItem("fullName");
      localStorage.removeItem("userName");
      window.sessionStorage.removeItem("ConnectWallet");
      account && deactivate();
    }
  };
  const getUserWallletApi = async () => {
    try {
      if (!isLogin) {
        return;
      }
      HandleExtraSpin();
      setisWalletUpdated(!isWalletUpdated);
      // setBlcLoad(true);
      const response = await getAPIHandler({
        endPoint: "viewWallet",
      });
      if (response.data.responseCode === 200) {
        setViewWalletData(response.data.result);

        localStorage.setItem("userBalance", response.data.result.balance);
      } else {
        setViewWalletData();
      }
      setBlcLoad(false);
    } catch (error) {
      setBlcLoad(false);
      console.log(error);
      setViewWalletData();
    }
  };

  // lottery list

  const getGlobalLotteryData = async () => {
    try {
      const res = await apiRouterCall({
        method: "GET",
        url: ApiConfig.getLottery,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("ConnectWallet")) {
      walletConnect();
    }
  }, [sessionStorage.getItem("ConnectWallet")]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      viewUserProfile(token);
      window.getUserWalletApi = getUserWallletApi;
    }
  }, [sessionStorage.getItem("token")]);

  useEffect(() => {
    if (account && chainId) {
      getTokenWalletBalance();
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  // useEffect(() => {
  //   getPotAmountIdApi();
  // }, []);

  let data = {
    userLoggedIn: isLogin,
    bonus200Check,
    userData,
    blcLoad,
    isWalletUpdated,
    toatlCounts,
    eyeView,
    viewWalletData,
    setEndtime,
    setTimeLeft,
    timeLeft,
    account,
    deactivate,
    library,
    tokenBalance,
    isPotAmount,
    openReferralModal,
    setOpenReferralModal,
    lotteryBuyDisAble,
    lastSpinDate,
    totalCount,
    setLotteryBuyDisAble,
    openDepositFlow,
    openSupport,
    setOpenSupport: (e) => setOpenSupport(e),
    setOpenDepositFlow: (e) => setOpenDepositFlow(e),
    walletConnect: walletConnect,
    viewUserProfile: () => viewUserProfile(sessionStorage.getItem("token")),
    // getUserWallletApi: ()=>viewUserProfile(sessionStorage.getItem("token")),
    getUserWallletApi: getUserWallletApi,
    getGlobalLotteryData: getGlobalLotteryData,
    setOpenDepositModal,
    openDepositModal,
    // getPotAmountIdApi: getPotAmountIdApi,
    userLogIn: (type, data) => {
      setIsLogin(type);
      setSession(data);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
