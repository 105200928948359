import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Slide,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Logo from "src/component/Logo";
import { FaTelegramPlane } from "react-icons/fa";
import { AiFillInstagram, AiOutlineWhatsApp } from "react-icons/ai";
import AuthModal from "./AuthModal";
import { AuthContext } from "src/context/Auth";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  mainBox: {
    padding: "10px 0 30px",
    zIndex: "999",
    position: "relative",
    background: "rgba(255, 255, 255, 0.02)",
    borderTop: "1px solid #2A305E",
    "& img": {
      "@media (max-width: 1300px)": {
        width: "95px",
      },
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    "& .justiContent": {
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        maxWidth: "105px",
      },
    },
    "& .termstext": {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    "& .textcenterBox": {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    "& .textrightBox": {
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    "& .socialCenter": {
      marginRight: "12px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    "& svg": {
      color: "#ffb000db",
      fontSize: "24px",
    },
    "& .bordercenterText": {
      marginLeft: "10px",
      borderLeft: "1px solid #2A305E",
      borderRight: "1px solid #2A305E",
      padding: "9px 10px",
    },
    "& .logodes": {
      width: "auto",
      maxWidth: "350px",
      fontSize: "13px",
      marginTop: "10px",
      [theme.breakpoints.only("xs")]: {
        maxWidth: "100%",
      },
    },
    "& .privacyText": {
      fontSize: "14px",
      // color: "orange",
      // textDecoration: "underline",
      color: "#fff",
      cursor: "pointer",
      whiteSpace: "pre",
      fontWeight: "400",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px !important",
        fontWeight: "300 !important",
      },
    },
    "& .displayStart": {
      // justifyContent: "end",
    },
    "& .displaySpacebetween": {
      flexWrap: "wrap",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isSignUpActive, setIsSignUpActive] = useState(false);
  const auth = useContext(AuthContext);
  

  return (
    <Box className={classes.mainBox}>
      <Container>
        <Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item lg={5} md={5} sm={6} xs={12} className="textcenterBox">
              <Box padding={0}>
                <Logo
                  onClick={() => {
                    localStorage.getItem("ADMIN") &&
                      localStorage.removeItem("ADMIN");
                    history.push("/");
                  }}
                  className="logoDrawerBox m-auto lg:m-0"
                  alt="Logo"
                />
                <Typography variant="body2" className="logodes">
                  A community of passionate gamers who have put together a
                  unique gaming platform that upgrades your gameplay experience
                  to a whole new level.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12} className="textcenterBox">
              <Box className="displayStart termstext">
                <Typography
                  variant="h6"
                  className="privacyText"
                  onClick={() => history.push("/features")}
                >
                  Our Platform Features
                </Typography>
                <Typography
                  variant="h6"
                  className="privacyText bordercenterText"
                  onClick={() => history.push("/privacy-policy")}
                >
                  Privacy Policy
                </Typography>

                <Typography
                  variant="h6"
                  className="privacyText"
                  onClick={() => history.push("/about")}
                  style={{ marginLeft: "10px" }}
                >
                  About Us
                </Typography>
                {/* <Typography
            variant="h6"
            className="privacyText"
            onClick={() => history.push("/features")}
            style={{ marginLeft: "10px" }}
          >
            Features
          </Typography> */}
              </Box>
              <Typography
            variant="h6"
            className="privacyText inline"
            // onClick={() => history.push("/features")}
          >  
            
            Support - <span> <a
            href={`mailto:support@betbyheart.com`}
          >
            support@betbyheart.com
          </a></span>
          </Typography>
              <Box className="mt-3">
            {!auth.userLoggedIn && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    setIsSignUpActive(false)
                    setOpen(true)
                  }}
              >
                Login
              </Button>
            )}
            {!auth.userLoggedIn && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsSignUpActive(true)
                  setOpen(true)
                }}
                style={{marginLeft : '.5rem'}}
                id="signup-btn"
              >
              Signup
            </Button>
          )}
            </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12} className="textrightBox flex flex-col items-center gap-y-2">
              <img
                src="images/join_textname.png"
                alt="images"
                className="justiContent"
              />

              <Box className="displayEnd socialCenter">
                <IconButton
                  href="https://t.me/+IK3LR5TPVhs1N2Fl"
                  target="_blank"
                  className="socialButton"
                >
                  <FaTelegramPlane />
                </IconButton>
                <IconButton
                  href="https://instagram.com/betbyheart6?igshid=OGQ5ZDc2ODk2ZA=="
                  target="_blank"
                  className="socialButton"
                >
                  <AiFillInstagram />
                </IconButton>
                <IconButton
                  href="https://www.whatsapp.com/channel/0029VaAbWMT0LKZ8fa1mev2j"
                  target="_blank"
                  className="socialButton"
                >
                  <AiOutlineWhatsApp />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          className="noPadding"
          onClose={() => {
            setOpen(false);
          }}
          maxWidth="lg"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <AuthModal 
              closeBox={() => setOpen(false)} 
              isSignUpActive={isSignUpActive}
              setIsSignUpActive={setIsSignUpActive}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}
