import { currencyUnit, virtualCurrency } from "src/constants";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  makeStyles,
  InputAdornment,
  IconButton,
  FormHelperText,
  Slide,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { handleNegativeValue, sortAddress } from "src/utils";
import CopyToClipboard from "react-copy-to-clipboard";
import { FileCopyOutlined } from "@material-ui/icons";
import { RxCross2 } from "react-icons/rx";
import { postAPIHandler } from "src/ApiConfig/service";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useDebounce from "src/component/customHook/Debounce";
import toast from "react-hot-toast";
import { Autocomplete } from "@material-ui/lab";
import QRCode from "qrcode.react";
import InfoIcon from "@material-ui/icons/Info";
import { AuthContext } from "src/context/Auth";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ModalDeposit({
  closeBox,
  getAPIHandler,
  axios,
  viewWalletData,
  openDepositFlow,
  setOpenDepositFlow,
  open,
  prePathname
}) {
  const modalRef = useRef(null);
    // const location = useLocation(); // Hook to get the current route

  useEffect(() => {
    // Close the modal when the route changes
    if(window.location.pathname !== prePathname)
    closeBox();
  }, [window.location.pathname]); // Run this effect whenever the route changes
console.log(prePathname ," ---- gsgs ",  window.location.pathname)

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = ""; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [open]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeBox();
    }
  };

  return (
    <div
      // onClick={handleClickOutside}
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        open ? "visible opacity-100" : "invisible opacity-0"
      } bg-black bg-opacity-80 transition-opacity duration-300 ease-in-out`}
    >
      <div
        ref={modalRef}
        className="h-[520px] rounded-lg shadow-lg relative m-4 w-[700px]"
        style={{
          maxWidth: "100%",
        }}
      >
        <DepositDialogContent
          closeBox={closeBox}
          getAPIHandler={getAPIHandler}
          axios={axios}
          viewWalletData={viewWalletData}
          openDepositFlow={openDepositFlow}
          setOpenDepositFlow={setOpenDepositFlow}
          open={open}
        />
      </div>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  LoosePopModalBox: {
    // position: "relative",

    "& h6": {
      color: "#fff",
    },
    "& .imgBox": {
      padding: "50px 0px 30px",
      "& img": {
        width: "100px",
      },
    },
  },
  sideContent: {
    textAlign: "center",
  },
  depositeQR: {
    " & span": {
      color: "#fff",
    },
    "& .link": {
      color: "navajowhite",
      textDecoration: "underline",
      cursor: "pointer",
      "&:hover": { opacity: "0.8" },
    },
  },
  formControl: {
    "& .MuiAutocomplete-popper": {
      "& .MuiPaper-elevation1": {
        padding: "0px !important",
      },
    },
  },
}));

const DepositDialogContent = ({
  closeBox,
  getAPIHandler,
  axios,
  viewWalletData,
  openDepositFlow,
  setOpenDepositFlow,
  open,
}) => {
  const classes = useStyle();
  // const [openDepositFlow, setOpenDepositFlow] = useState(false);
  const [selectCurrency, setSelectCurrency] = useState("");
  const [amountValue, setAmountValue] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");
  const [equivalentData, setEquivalentData] = useState();
  const [minDepositAmount, setMinDepositAmount] = useState();
  const [equivalentPayData, setEquivalentPayData] = useState();
  const [isCurrencySelected, setCurrencySelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [coinListData, setCoinListData] = useState([]);
  const deb = useDebounce(amountValue, 500);
  const deb1 = useDebounce(selectCurrency, 500);
  const { openSupport, setOpenSupport } = useContext(AuthContext);
  const checkEtherCoin =
    selectCurrency === "USDTERC20" || selectCurrency === "BTC";
  const getCoinListApi = async (source) => {
    try {
      const response = await getAPIHandler({
        endPoint: "getCoin",
        source: source,
      });
      if (response?.data?.responseCode === 200) {
        setCoinListData(response?.data?.result?.selectedCurrencies);
      } else {
        setCoinListData();
      }
    } catch (error) {
      console.log(error);
      setCoinListData();
    }
  };

  const getDepositAmoutApi = async () => {
    try {
      setErrorMsg("");
      setIsLoading(true);
      const response = await getAPIHandler({
        endPoint: "getDepositBalence",
        paramsData: {
          fromCoin: virtualCurrency.toLocaleLowerCase(),
          toCoin: selectCurrency.toLocaleLowerCase(),
          amount: amountValue,
        },
      });
      if (response?.data?.responseCode === 200) {
        setIsLoading(false);
        setEquivalentData(response?.data?.result);
      } else {
        setEquivalentData();
        setIsLoading(false);
        setErrorMsg(response?.data?.responseMessage);
      }
    } catch (error) {
      setEquivalentData();
      setIsLoading(false);
      setErrorMsg(error?.response?.data?.responseMessage);
      console.log(error);
    }
  };
  const getDepositMinAmoutApi = async () => {
    try {
      setIsLoading(true);
      const response = await getAPIHandler({
        endPoint: "getMinimumDeposite",
        paramsData: {
          fromCoin: selectCurrency.toLocaleLowerCase(),
          toCoin: virtualCurrency.toLocaleLowerCase(),
        },
      });
      if (response?.data?.responseCode === 200) {
        setIsLoading(false);
        setMinDepositAmount(response?.data?.result?.fiat_equivalent);
      } else {
        setMinDepositAmount();
        setIsLoading(false);
      }
    } catch (error) {
      setMinDepositAmount();
      setIsLoading(false);
      console.log(error);
    }
  };
  const sendDepositMoney = async () => {
    try {
      setErrorMsg("");
      setIsLoading(true);
      const response = await postAPIHandler({
        endPoint: "depositMoney",
        dataToSend: {
          pay_currency: selectCurrency.toLocaleLowerCase(),
          price_amount: amountValue,
        },
      });
      if (response?.data?.responseCode === 200) {
        setEquivalentPayData(response?.data?.result);
        setIsLoading(false);
        setCurrencySelected(true);
      } else {
        setIsLoading(false);
        setEquivalentPayData();
        setErrorMsg(response?.data?.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      setEquivalentPayData();
      setErrorMsg(error?.response?.data?.responseMessage);
      console.log(error);
    }
  };

  useEffect(() => {
    deb !== "" &&
      selectCurrency !== "" &&
      !checkEtherCoin &&
      amountValue >= minDepositAmount &&
      getDepositAmoutApi();
  }, [deb, selectCurrency, deb1]);

  useEffect(() => {
    selectCurrency !== "" && getDepositMinAmoutApi();
  }, [selectCurrency]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    getCoinListApi(source);
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <>
      <Box className={`container`} id="container">
        <RxCross2
          className="closeIcon"
          style={{ color: "#fff", cursor: "pointer" }}
          onClick={() => {
            closeBox();
            setSelectCurrency("");
            setAmountValue("");
            setEquivalentData();
          }}
        />
        <Box className="form-container sign-in-container">
          <form action="#">
            <Box className="displayStart">
              {isCurrencySelected && (
                <ArrowBackIcon
                  style={{ color: "#fff", cursor: "pointer" }}
                  onClick={() => setCurrencySelected(false)}
                />
              )}
              <Typography
                variant="h6"
                style={{ fontWeight: "600", marginLeft: "10px", color: "#fff" }}
              >
                DEPOSIT
              </Typography>
              <IconButton
                size="small"
                onClick={() => {
                  setOpenDepositFlow(true);
                }}
              >
                <InfoIcon style={{ color: "#fff" }} />
              </IconButton>
            </Box>

            {isCurrencySelected ? (
              <VerifyModal
                equivalentData={equivalentPayData}
                selectCurrency={selectCurrency}
                openSupport={openSupport}
                setOpenSupport={(e) => setOpenSupport(e)}
              />
            ) : (
              <>
                <Box mb={2} mt={3}>
                  <Box className="SelectorBox">
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Autocomplete
                        freeSolo
                        options={coinListData ? coinListData : []}
                        getOptionLabel={(option) => option}
                        value={selectCurrency}
                        disabled={isLoading}
                        PaperComponent="Box"
                        onChange={(event, newValue) => {
                          setSelectCurrency(
                            newValue ? newValue.toUpperCase() : ""
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select coin"
                          />
                        )}
                        noOptionsText="No matching coin found"
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box mb={2}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="number"
                    placeholder={
                      checkEtherCoin
                        ? "Amount you will be paying"
                        : "Enter Amount"
                    }
                    value={amountValue}
                    disabled={selectCurrency === "" || isLoading}
                    onChange={(e) => setAmountValue(e.target.value)}
                    onKeyDown={(event) => handleNegativeValue(event)}
                    onKeyPress={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "*" ||
                        event?.key === "/"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            variant="body1"
                            style={{ color: "#f4a91b" }}
                          >
                            {" "}
                            {currencyUnit}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error>
                    {amountValue !== "" &&
                      amountValue < minDepositAmount &&
                      `Please enter greater than ${parseFloat(
                        minDepositAmount
                      ).toFixed(3)} ${currencyUnit}`}
                  </FormHelperText>
                  {(selectCurrency === "USDTERC20" ||
                    selectCurrency === "BTC") && (
                    <Box mt={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontSize: "12px",
                          lineHeight: "1.25",
                          letterSpacing: "0.000938em",
                        }}
                      >
                        The equivalent amount of {currencyUnit} that you receive
                        is reflected on Afterpay transactions.
                      </Typography>
                    </Box>
                  )}
                </Box>
                {!checkEtherCoin && (
                  <Box mb={1}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      disabled
                      value={equivalentData?.estimated_amount}
                      type="number"
                      placeholder="Amount you will be paying"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {selectCurrency !== "" && selectCurrency}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                )}
                <Box mt={2}>
                  <FormHelperText error>
                    {errorMsg !== "" && errorMsg}
                  </FormHelperText>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={
                      amountValue < minDepositAmount ||
                      amountValue === "" ||
                      (!checkEtherCoin && !equivalentData) ||
                      isLoading ||
                      selectCurrency === ""
                    }
                    onClick={() => sendDepositMoney()}
                  >
                    {isLoading ? "Loading..." : "Pay"}
                  </Button>
                </Box>
                <iframe
                  className="mt-3 rounded-lg"
                  height="315"
                  src="https://www.youtube.com/embed/xxHa2TydxLs?si=RRiE0rFgowZ44kC3"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  referrerPolicy="strict-origin-when-cross-origin"
                ></iframe>
              </>
            )}
          </form>
        </Box>
        <Box className="overlay-container">
          <Box className="overlay">
            <Box className={`overlay-panel overlay-right textLeft`}>
              <RxCross2
                className="closeIcon"
                style={{ color: "#fff", cursor: "pointer" }}
                onClick={() => {
                  closeBox();
                  setSelectCurrency("");
                  setAmountValue("");
                  setEquivalentData();
                }}
              />
              <Box p={2} className={classes.sideContent}>
                <img
                  src="images/logo.png"
                  alt="BetbyHeart"
                  style={{ maxWidth: "135px", margin: "auto" }}
                />
                {/* <img
          src="/images/deposite.svg"
          style={{ width: "auto", maxWidth: "223px", marginTop: "28px" }}
        /> */}
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{ color: "#8C8FAF", marginTop: "1rem" }}
                >
                  Deposit funds into your internal wallet with absolute
                  security.
                </Typography>
                <Box mt={2}>
                  <img
                    src="images/deposit.png"
                    alt="BetbyHeart"
                    style={{ maxWidth: "100%" }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
const VerifyModal = ({
  equivalentData,
  selectCurrency,
  openSupport,
  setOpenSupport,
}) => {
  const classes = useStyle();
  const payAddress = equivalentData?.pay_address;

  const openZendesk = () => {
    if (window.zE) {
      window.zE("messenger", "open");
    } else {
      console.error("Zendesk widget is not loaded yet.");
    }
  };
  return (
    <>
      <Box m={2} className="displayCenter">
        <QRCode value={payAddress} size={256} />
      </Box>
      <Box mt={1} className={`${classes.depositeQR} displayColumn`}>
        <Typography variant="body2" color="primary">
          Amount :{" "}
          <span>
            {equivalentData?.pay_amount ? equivalentData?.pay_amount : "0"}{" "}
            {selectCurrency}
            {equivalentData?.pay_amount > 0 && (
              <CopyToClipboard text={equivalentData?.pay_amount}>
                <IconButton style={{ padding: "0px" }}>
                  <FileCopyOutlined
                    style={{
                      cursor: "pointer",
                      color: "rgba(255, 202, 100, 1)",
                      fontSize: "18px",
                      marginLeft: "5px",
                    }}
                    onClick={() => toast.success("Copied")}
                  />
                </IconButton>
              </CopyToClipboard>
            )}
          </span>
        </Typography>
        <Box mt={1}>
          <Typography variant="body2" color="primary">
            Paying address :{" "}
            <span>
              {sortAddress(equivalentData?.pay_address)}
              <CopyToClipboard text={equivalentData?.pay_address}>
                <IconButton style={{ padding: "0px" }}>
                  <FileCopyOutlined
                    style={{
                      cursor: "pointer",
                      color: "rgba(255, 202, 100, 1)",
                      fontSize: "18px",
                      marginLeft: "5px",
                    }}
                    onClick={() => toast.success("Copied")}
                  />
                </IconButton>
              </CopyToClipboard>
            </span>
          </Typography>
        </Box>
        <Box mt={2} textAlign="center">
          <Typography
            variant="body2"
            color="secondary"
            style={{ color: "#8C8FAF", fontSize: "12px" }}
          >
            Need deposit assistance? <br />
            <span className="link" onClick={() => openZendesk()}>
              Click here
            </span>{" "}
            to connect with our LIVE agent. <br />
            <span className="link" onClick={() => openZendesk()}>
              How to deposit
            </span>
          </Typography>
        </Box>
      </Box>
    </>
  );
};
