import { counterTimer } from "src/utils";
import React, { useState, useEffect, useMemo } from "react";

function NextSpinCountDown({ endTime, text = "Your next free spin drops in " }) {
  const [countdown, setCountdown] = useState("");

  const endTimestamp = useMemo(() => endTime, [endTime]);

  useEffect(() => {
    const calculateCountdown = () => {
      const data = counterTimer(endTimestamp);

      if (data.days > 0) {
        return data.days > 1 ? `${data.days} days` : `${data.days} day`;
      } else if (data.hours > 0 || data.minutes > 0) {
        return `${data.hours}h : ${data.minutes}m : ${data.seconds}s`;
      } else if (data.seconds > 0) {
        return `${data.seconds}s`;
      }
      return "";
    };

    // Initial calculation
    setCountdown(calculateCountdown());

    const interval = setInterval(() => {
      const updatedCountdown = calculateCountdown();
      if (updatedCountdown) {
        setCountdown(updatedCountdown);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [endTimestamp]);

  return (
    <div className="free-spin-countdown relative w-full font-bold text-xs sm:text-sm md:text-base z-10 overflow-hidden text-3d ">
      {text} {countdown}
    </div>
  );
}

export default NextSpinCountDown;
