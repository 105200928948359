import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles, Box } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { AuthContext } from "src/context/Auth";
import { NavLink as RouterLink } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    padding: "0px 10px",
  },
  button: {
    color: "#000000",
    padding: "10px 0",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonLeaf: {
    color: "rgba(255, 255, 255, 0.70)",
    width: "100%",
    margin: "2px 8px",
    fontSize: "15px",
    fontWeight: "300",
    borderRadius: "10px",
    letterSpacing: "0",
    textTransform: "none",
    border: "1px solid transparent",
    justifyContent: "flex-start",
    "&.depth-0": {
      "& $title": {
        fontWeight: "400",
      },
    },
    "&:hover": {
      borderRadius: "10px",
      border: "1px solid #ffca0633",
      background: "rgba(34, 39, 74, 0.35)",
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    color: "rgba(255, 255, 255, 0.6)",
    "& svg": {
      color: "#AC7AE4",
    },
  },
  title: {
    marginRight: "auto",
    textTransform: "uppercase",
    fontWeight: "300",
  },
  title1: {
    fontSize: "12px",
    fontWeight: "200",
    marginRight: "auto",
    textTransform: "capitalize",
  },
  active: {
    borderRadius: "10px",
    border: "1px solid #ffca0633",
    background: "rgba(34, 39, 74, 0.35)",
  },

  activeSub: {
    // color: "#ffff",
    // "& span": {
    color: "#FEB53D",
    fontWeight: "500",
    // },
    // background: "#FEB53D",
    fontWeight: theme.typography.fontWeightRegular,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "#ffff",
    },
  },
  iconCircle: {
    "& img": {
      width: "auto",
      width: "40px",
      marginRight: "13px",
      objectFit: "cover",
      border: "1px solid #2a2e52",
      borderRadius: "8px",
    },
  },
}));
const AdminSection = [];
const sections = [];
const NavItem = ({
  children,
  className,
  depth,
  href,
  img,
  info: Info,
  open: openProp,
  title,
  title1,
  isSub,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(openProp);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;
  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }
  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={{ color: "#ccc" }}
        >
          <Box className={classes.iconCircle}>
            {img && (
              <img src={img} alt={title} className={classes.icon} size="20" onDragStart={(e) => e.preventDefault()} />
            )}
          </Box>
          <Box>
            <span className={classes.title}>{title}</span>
          </Box>

          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <Box>
      <ListItem
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          activeClassName={isSub ? classes.activeSub : classes.active}
          className={
            !localStorage.getItem("ADMIN")
              ? clsx(classes.buttonLeaf, `depth-${depth}`)
              : "buttonLeaf1"
          }
          exact
          style={style}
          // to={href}
          // component={RouterLink}
          onClick={() => {
            if (href === "/blackjack") {
              // window.open(
              //   `${href}#${auth?.userData?._id ? auth?.userData?._id : ""}`,
              //   "_self"
              // );
              history.push(
                `${href}#${auth?.userData?._id ? auth?.userData?._id : ""}`
              );
            } else {
              history.push(`${href}`);
            }
          }}
        >
          <Box className={classes.iconCircle}>
            {img && <img src={img} alt={title} className={classes.icon} onDragStart={(e) => e.preventDefault()} />}
          </Box>
          <Box align="left">
            <span className={classes.title}>{title}</span>
            <Box style={{ marginTop: "-6px" }}>
              <span className={classes.title1}>{title1}</span>
            </Box>
          </Box>

          {Info && <Info />}
        </Button>
      </ListItem>
    </Box>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  title1: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
