import { Dialog, DialogContent, Box, DialogTitle } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Slide from "@material-ui/core/Slide";
import { RxCross2 } from "react-icons/rx";
import ApiConfig from "src/ApiConfig/ApiConfig";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VideoComp = ({ openDepositFlow, setOpenDepositFlow, auth }) => {
  const [videoSrc, setVideoSrc] = useState("");
  const [videoTitle, setVideoTitle] = useState("");

  const updateGuideView = async () => {
    try {
      await axios({
        method: "GET",
        url: ApiConfig.updateGuideView,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
    } catch (error) {
      console.log("Error:", error);
    } finally {
      auth.viewUserProfile();
    }
  };

  const fetchVideoTitle = async (url) => {
    try {
      const videoId = url.split("/embed/")[1]?.split("?")[0];
      if (!videoId) {
        console.error("Invalid YouTube video URL.");
        setVideoTitle(
          "How to Deposit Money in Your Bet By Heart Account | Recharge your wallet with crypto wallet."
        );
      }

      const response = await axios.get(
        `https://www.youtube.com/watch?v=${videoId}`
      );
      const html = response.data;

      const titleMatch = html.match(/<title>(.*?)<\/title>/);
      if (titleMatch && titleMatch[1]) {
        const rawTitle = titleMatch[1];
        const videoTitle = rawTitle.replace(" - YouTube", "").trim();
        setVideoTitle(videoTitle);
      } else {  setVideoTitle(
        "How to Deposit Money in Your Bet By Heart Account | Recharge your wallet with crypto wallet."
      );
      }
    } catch (error) {
      console.error("Error fetching video title:", error.message);
      setVideoTitle(
        "How to Deposit Money in Your Bet By Heart Account | Recharge your wallet with crypto wallet."
      );
    }
  };

  useEffect(() => {
    if (openDepositFlow) {
      updateGuideView();
      const videoUrl =
        "https://www.youtube.com/embed/xxHa2TydxLs?si=RRiE0rFgowZ44kC3";
      setVideoSrc(videoUrl);
      fetchVideoTitle(videoUrl);
    } else {
      setVideoSrc("");
    }
  }, [openDepositFlow]);

  return (
    <Dialog
      open={openDepositFlow}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpenDepositFlow(false)}
      maxWidth="lg"
      className="noPadding testCss"
      style={{ zIndex: 1500 }}
      PaperProps={{
        style: {
          backgroundColor: "#000000", // Black background for the modal
          color: "#FFFFFF", // White text color
        },
      }}
    >
      <DialogTitle
        style={{
          fontSize: "14px",
          color: "#FFFFFF", // Ensure the title text is white
          borderBottom: "1px solid #333333", // Add a subtle border
        }}
      >
        {videoTitle}
        <RxCross2
          className="closeIcon"
          onClick={() => setOpenDepositFlow(false)}
          style={{
            float: "right",
            cursor: "pointer",
            color: "#FFFFFF", // White close icon
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Box
          id="container"
          style={{
            backgroundColor: "#000000", // Black background for content
            padding: "10px",
          }}
        >
          <iframe
            style={{ width: "100%", height: "425px", border: "none" }}
            src={videoSrc}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VideoComp;
