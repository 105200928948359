import React, { useState, useEffect, useRef } from "react";
import { Button, useTheme, useMediaQuery } from "@material-ui/core"; 
import { formatNumber } from "src/utils";

const LivePlayersPopover = ({ totalCounts, text, isSmall }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [isOpen, setIsOpen] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const popoverRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const detectTouchDevice = () => {
      setIsTouchDevice(
        "ontouchstart" in window || navigator.maxTouchPoints > 0
      );
    };
    detectTouchDevice();

    if (isTouchDevice) {
      const handleOutsideClick = (event) => {
        if (
          popoverRef.current &&
          !popoverRef.current.contains(event.target) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener("click", handleOutsideClick);
      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }
  }, [isTouchDevice]);

  const handlePopoverToggle = () => {
    if (isTouchDevice) {
      setIsOpen(!isOpen);
    }
  };

  const handlePopoverOpen = () => {
    if (!isTouchDevice) {
      setIsOpen(true);
    }
  };

  const handlePopoverClose = () => {
    if (!isTouchDevice) {
      setIsOpen(false);
    }
  };

  return (
    totalCounts > 0 && (
      <div
        className="relative z-50"
        style={{ position: "relative" }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handlePopoverToggle}
      >
        {isSmall ? (
          <button
            ref={buttonRef}
            className="muteButton flex items-center justify-center rounded-full p-3 shadow-md hover:shadow-lg"
          >
            <img
              src={"/images/live-streaming1.png"}
              alt={"live stream icons"}
              className="w-6 h-6 text-white mr-2"
            />{" "}
            <span className="ml-2 text-sm">{formatNumber(totalCounts)}</span>
          </button>
        ) : (
          <Button
            ref={buttonRef}
            variant="contained"
            color="secondary"
            style={{
              backgroundColor: "#FFFFFF05",
              padding: isMobile ? "10px 10px" : "10px 39px",
            }}
        
          >
           
            <img
              src={"/images/live-streaming1.png"}
              alt={"live stream icons"}
              className={`${isMobile?"w-4 h-4" :"w-6 h-6"} text-white mr-2`}
            />

            {formatNumber(totalCounts)}
          </Button>
        )}

        {isOpen && (
          <div
            ref={popoverRef}
            className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 z-10 p-3 bg-[#1C1F36] text-white border border-[#f4a91b54] rounded-md shadow-lg"
            style={{
              textAlign: "center",
              animation: "fadeIn 0.2s ease-in-out",
              position: "absolute",
              marginTop: "8px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <p className="text-xs sm:text-sm md:text-base whitespace-nowrap">
              {text}
            </p>
            <div
              style={{
                content: '""',
                position: "absolute",
                bottom: "100%",
                left: isSmall ? "80%" : "50%", 
                transform: isSmall ? "translateX(0)" : "translateX(-50%)", 
                width: "0",
                height: "0",
                borderLeft: "6px solid transparent",
                borderRight: "6px solid transparent",
                borderBottom: "6px solid #f4a91b54",
              }}
            ></div>
          </div>
        )}
      </div>
    )
  );
};

export default LivePlayersPopover;
