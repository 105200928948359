import { Button } from "@material-ui/core";
import React from "react";

export default function ModalWith200Bonus({ closeBox, handleOpenDeposit }) {
  return (
    <div
      className="spin-modal1 flex space-between items-center justify-between "
      style={{
        backgroundImage: `url(${"/images/spinBanner.webp"})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
    >
      <img src="/images/upTo200Bonus1.jpg" alt={"up to 200% bonus"} />
      <div
        className={`spinCloseButton pointer-events-auto`}
        onClick={() => {
          closeBox(false);
        }}
      >
        <img src="/images/closeIcon.svg" alt="closeIcon" width={30} />
      </div>
      <div className={" absolute bottom-0 "}>
   
        <Button
          variant="contained"
          onClick={() => {
            handleOpenDeposit();
            closeBox(false);
          }}
          color="primary"
          style={{
            color: "#520d07",
            fontWeight: "bolder",
            background: "#f9f8ab",
            border: "4px solid #e9ca60",
          }}
        >
          DEPOSIT NOW
        </Button>
      </div>
    </div>
  );
}
