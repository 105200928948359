import React, { createContext, useState, useEffect } from "react";
import ApiConfig, { baseurlSocket } from "src/ApiConfig/ApiConfig";

export const WebSocketContext = createContext();

const web = new WebSocket(baseurlSocket);

export default function WebSocketProvider(props) {
  const [liveCricketMatch, setLiveCricketMatch] = useState([]);
  const [liveFootBallMatch, setLiveFootBallMatch] = useState([]);
  const [btc, setBtc] = useState({
    price: null,
    checkDown: undefined,
  });
  const [eth, setEth] = useState({
    price: null,
    checkDown: undefined,
  });
  const [trx, setTrx] = useState({
    price: null,
    checkDown: undefined,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      web.onopen = () => {
        const dataToSend = {
          requestType: "getMarketPriceCMC",
          coinType: "All",
          fromDate: new Date(),
        };

        web.send(JSON.stringify(dataToSend));
        let prevBtcVal = -1;
        let prevBTC = -1;
        let prevCloseTime_btc = new Date().getTime();
        let prevEthVal = -1;
        let prevETH = -1;
        let prevCloseTime_eth = new Date().getTime();
        let prevTrxVal = -1;
        let prevTRX = -1;
        let prevCloseTime_trx = new Date().getTime();

        web.onmessage = async (event) => {
          let obj = JSON.parse(event.data);
          const result = obj.result;

          if (Array.isArray(result) && result.length === 0) return;

          let checkUpAndDown = false;
          let checkUpAndDownEth = false;
          let checkUpAndDownTRX = false;
          const btcCloseVal =
            result?.filteredBTCData?.quotes[0]?.quote?.USD?.close;
          const ethCloseVal =
            result?.filteredETHBTCData?.quotes[0]?.quote?.USD?.close;
          const trxCloseVal =
            result?.filteredTRXBTCData?.quotes[0]?.quote?.USD?.close;

          const btc_closingtime = new Date(
            result?.filteredBTCData?.quotes[0]?.time_close
          ).getTime();
          const eth_closingtime = new Date(
            result?.filteredETHBTCData?.quotes[0]?.time_close
          ).getTime();
          const trx_closingtime = new Date(
            result?.filteredTRXBTCData?.quotes[0]?.time_close
          ).getTime();

          if (prevBtcVal === -1) {
            prevBtcVal = btcCloseVal;
            prevBTC = btcCloseVal;
            prevCloseTime_btc = btc_closingtime;
          } else if (
            btcCloseVal !== prevBTC ||
            btcCloseVal > prevBTC ||
            btcCloseVal < prevBTC
          ) {
            checkUpAndDown = btcCloseVal > prevBTC;
            if (prevCloseTime_btc !== btc_closingtime) {
              prevBTC = prevBtcVal;
              prevBtcVal = btcCloseVal;
              prevCloseTime_btc = btc_closingtime;
            }
          }

          // Update BTC state
          setBtc({
            price: btcCloseVal,
            checkDown: checkUpAndDown,
          });

          // price up down login for eth
          if (prevEthVal === -1) {
            prevEthVal = ethCloseVal;
            prevETH = ethCloseVal;
            prevCloseTime_eth = eth_closingtime;
          } else if (
            ethCloseVal !== prevETH ||
            ethCloseVal > prevETH ||
            ethCloseVal < prevETH
          ) {
            checkUpAndDownEth = ethCloseVal > prevETH;
            if (prevCloseTime_eth !== eth_closingtime) {
              prevETH = prevEthVal;
              prevEthVal = ethCloseVal;
              prevCloseTime_eth = eth_closingtime;
            }
          }

          // Update ETH state
          setEth({
            price: ethCloseVal,
            checkDown: checkUpAndDownEth,
          });

          // price up down login for eth
          if (prevTrxVal === -1) {
            prevTrxVal = trxCloseVal;
            prevTRX = trxCloseVal;
            prevCloseTime_trx = trx_closingtime;
          } else if (
            trxCloseVal !== prevTRX ||
            trxCloseVal > prevTRX ||
            trxCloseVal < prevTRX
          ) {
            checkUpAndDownTRX = trxCloseVal > prevTRX;
            if (prevCloseTime_trx !== trx_closingtime) {
              prevTRX = prevTrxVal;
              prevTrxVal = trxCloseVal;
              prevCloseTime_trx = trx_closingtime;
            }
          }

          setTrx({
            price: trxCloseVal,
            checkDown: checkUpAndDownTRX,
          });

          setLoading(false);
        };
      };
      return () => {
        web.close();
      };
    } catch (err) {
      setLoading(false);
    }
  }, []);
  

  let data = {
    btc,
    eth,
    trx,
    loading,
    liveFootBallMatch,
    setLiveFootBallMatch: (e) => setLiveFootBallMatch(e),
    liveCricketMatch,
    setLiveCricketMatch: (e) => setLiveCricketMatch(e),
  };

  return (
    <WebSocketContext.Provider value={data}>
      {props.children}
    </WebSocketContext.Provider>
  );
}
