import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopBar from "./TopBar";
import Footer from "./Footer";
import { AuthContext } from "../../context/Auth";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#141627",
    position: "relative",
    zIndex: "9",
  },
  MainLayout: {
    minHeight: "calc(100vh - 415px)",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const [currentUrl, setCurrentUrl] = useState("")

  useEffect(() => {
    setCurrentUrl(window.location.href)
  }, [window.location.href])


  const { getUserWallletApi, openSupport } = useContext(AuthContext);


//  <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=3SjtUFnzJTbCBzqvrgHFVulunzgRds0oQBYdEoCV"> </script>




  // const loadZendeskScript = () => {
  //   if (document.getElementById('ze-snippet')) return;
  //   const script = document.createElement('script');
  //   script.id = 'ze-snippet';
  //   script.src = 'https://static.zdassets.com/ekr/snippet.js?key=528acb82-3f6e-4123-b783-2a892a77848b';
  //   script.async = true;
  //   script.onload = () => {
  //     window.zE('messenger', 'show');
  //     window.zE('messenger', 'open');
  //   };
  //   document.head.appendChild(script);
  // };

  // useEffect(() => {
  //   loadZendeskScript()
  // }, [openSupport])

  const loadZendeskScript = () => {
    if (document.getElementById('ze-snippet')) return;

    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=528acb82-3f6e-4123-b783-2a892a77848b';
    script.async = true;

    script.onload = () => {
      console.log('Zendesk script loaded successfully');
    };

    script.onerror = () => {
      console.error('Failed to load the Zendesk script');
    };

    document.head.appendChild(script);
  };



  useEffect(() => {
    loadZendeskScript();
  }, []);




  useEffect(() => {
    getUserWallletApi();
  }, [])
  return (
    <div className={classes.root}>
      <TopBar />
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
