import React, { useState, useEffect, useRef } from "react";
import { Button, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { fixDecimal } from "src/utils";
import { currencyUnit } from "src/constants";
import axios from "axios";
import { baseurl } from "src/ApiConfig/ApiConfig";

const DepositAndBonusPopover = ({ auth }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [isOpen, setIsOpen] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [rollingData, setRollingData] = useState([]);
  const popoverRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const detectTouchDevice = () => {
      setIsTouchDevice(
        "ontouchstart" in window || navigator.maxTouchPoints > 0
      );
    };
    detectTouchDevice();

    if (isTouchDevice) {
      const handleOutsideClick = (event) => {
        if (
          popoverRef.current &&
          !popoverRef.current.contains(event.target) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener("click", handleOutsideClick);
      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }
  }, [isTouchDevice]);

  const handlePopoverToggle = () => {
    if (isTouchDevice) {
      setIsOpen(!isOpen);
    }
  };

  const handlePopoverOpen = () => {
    if (!isTouchDevice) {
      setIsOpen(true);
    }
  };

  const handlePopoverClose = () => {
    if (!isTouchDevice) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    async function getDetail() {
      await axios
        .get(`${baseurl}/api/v1/user/rolling-details`, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((info) => {
          setRollingData(info.data.result);
        });
    }
    getDetail();
  }, [auth.isWalletUpdated]);

  return (
    <div
      className="relative z-50"
      style={{ position: "relative" }}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      onClick={handlePopoverToggle}
    >
      <Button
        ref={buttonRef}
        variant="contained"
        color="secondary"
        style={{
          backgroundColor: "#FFFFFF05",
          padding: isMobile ? "10px 10px" : "10px 39px",
        }}
      >
        <img
          src="images/walletIcon.svg"
          alt="wallet"
          height={isMobile ? "18px" : "26px"}
        />
        &nbsp;
        {auth?.blcLoad
          ? "0"
          : auth?.viewWalletData?.balance
          ? fixDecimal(auth?.viewWalletData?.balance, 2)
          : "0"}{" "}
        {currencyUnit}
      </Button>

      {isOpen && (
        <div
          ref={popoverRef}
          className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 z-10 p-3 bg-[#1C1F36] text-white border border-[#f4a91b54] rounded-md shadow-lg"
          style={{
            textAlign: "start",
            animation: "fadeIn 0.2s ease-in-out",
            position: "absolute",
            marginTop: "8px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div>
            <Typography
              variant="subtitle1"
              className="text-xs sm:text-sm md:text-base whitespace-nowrap"
            >
              <span
                style={{
                  fontWeight: 600,
                  color: "#CA772A",
                }}
              >
                Deposit Balance:{" "}
              </span>
              {Number(
                fixDecimal(
                  rollingData?.remainingDeposit > 0
                    ? rollingData?.remainingDeposit
                    : 0,
                  2
                )
              ) || "0"}{" "}
              {currencyUnit}
            </Typography>
            <Typography
              variant="subtitle1"
              className="text-xs sm:text-sm md:text-base whitespace-nowrap"
            >
              <span
                style={{
                  fontWeight: 600,
                  color: "#CA772A",
                }}
              >
                Bonus Balance:{" "}
              </span>
              {Number(
                fixDecimal(
                  rollingData?.remainingBonus > 0
                    ? rollingData?.remainingBonus
                    : 0,
                  2
                )
              ) || "0"}{" "}
              {currencyUnit}
            </Typography>

            <Typography
              variant="subtitle1"
              className="text-xs sm:text-sm md:text-base whitespace-nowrap"
            >
              <span
                style={{
                  fontWeight: 600,
                  color: "#CA772A",
                }}
              >
                Withdrawable Balance :{" "}
              </span>
              {Number(
                fixDecimal(
                  rollingData?.withdrawAmount > 0
                    ? rollingData?.withdrawAmount
                    : 0,
                  2
                )
              ) || "0"}{" "}
              {currencyUnit}
            </Typography>
          </div>
          <div
            style={{
              content: '""',
              position: "absolute",
              bottom: "100%",
              left: "50%",
              transform: "translateX(-50%)",
              width: "0",
              height: "0",
              borderLeft: "6px solid transparent",
              borderRight: "6px solid transparent",
              borderBottom: "6px solid #f4a91b54",
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default DepositAndBonusPopover;
